import React, { useContext, useEffect } from "react";
import { onSuccess, onFailure } from "./globalMethods";
// import { GoogleLogin } from "react-google-login";
import { FcGoogle } from "react-icons/fc";
import { ToastContainer } from "react-toastify";
import { UserContext } from "../../context/userContext";
import { setlocalStorage } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

export default function UserLogin() {
  const { userData, updateUserData } = useContext(UserContext);
  let nav = useNavigate();

  useEffect(() => {}, [userData]);

  const login = useGoogleLogin({
    onSuccess: async (respose) => {
      await axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: {
            Authorization: `Bearer ${respose.access_token}`,
          },
        })
        .then(async (res) => {
          loginSuccess(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onError: () => {
      onFailure();
    },
  });

  const loginSuccess = async (data) => {
    const statusCode = await onSuccess(data);
    if (statusCode == 200) {
      nav("/");
      setlocalStorage("uid", data?.email);
      setlocalStorage("userInfo", JSON.stringify(data));
      updateUserData(data);
    }
  };

  return (
    <>
      <button onClick={login} className="px-3 py-2 loginGoogleBtn">
        <FcGoogle className="me-2" style={{ marginTop: "-4px" }} />
        Continue with Google
      </button>
      {/* <GoogleLogin
        jsSrc="https://apis.google.com/js/api.js"
        style={{ width: "500px" }}
        clientId={clienId}
        render={(renderProps) => (
          <button
            onClick={renderProps.onClick}
            className="px-3 py-2 loginGoogleBtn">
            <FcGoogle className="me-2" style={{ marginTop: "-4px" }} />
            Continue with Google
          </button>
        )}
        buttonText="Login"
        onSuccess={loginSuccess}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
        isSignedIn={true}
      /> */}
      <ToastContainer />
    </>
  );
}
