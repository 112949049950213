import React, { useState } from "react";
import { Card } from "react-bootstrap";

const linkText = {
  textDecoration: "none",
  color: "#d10d0d",
  cursor: "pointer",
};

const cardStyle = {
  minHeight: "370px",
};

export default function AboutCard({ image, title, contents }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card style={cardStyle} className="mb-4">
      <Card.Img variant="top" src={image} height="280px" />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text
          style={{
            display: "-webkit-box",
            WebkitLineClamp: isExpanded ? "none" : 5,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {contents}
        </Card.Text>
        {title !== " " && (
          <Card.Link style={linkText} onClick={toggleReadMore}>
            {isExpanded ? "Read Less" : "Read More"}
          </Card.Link>
        )}
      </Card.Body>
    </Card>
  );
}
