import { createContext, useState } from "react";
import { removeLocalStorage } from "../utils/helper";

export const UserContext = createContext({
  updateUserData: () => {},
  userLogout: () => {},
  userLogin: () => {},
  userData: "",
});

const UserContextProvider = ({ children }) => {
  const initialValue = {
    name: "",
    email: "",
    isLoggedin: false,
  };
  const [userData, setUserData] = useState(initialValue);

  const updateUserData = (data) => {
    setUserData((prev) => {
      return { ...prev, data };
    });
  };

  const userLogin = () => {};

  const userLogout = () => {
    removeLocalStorage("uid");
    removeLocalStorage("userInfo");
    setUserData(initialValue);
  };

  return (
    <UserContext.Provider
      value={{ updateUserData, userData, userLogout, userLogin }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
