import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App/App";
import "./assets/css/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import "sweetalert2/src/sweetalert2.scss";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { clienId } from "./components/Login/globalMethods";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={clienId}>
      <App />
    </GoogleOAuthProvider>
  </React.StrictMode>
);
