import React from "react";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { TbCurrencyRupee } from "react-icons/tb";

export default function CartCard(props) {
  let { data, deleteValue, setData, cartData } = props;

  const handleDecrement = (i) => {
    let info = [...data];
    if (info[i].quantity != 1) info[i].quantity = Number(info[i].quantity) - 1;
    setData(info);
    cartData(i);
  };

  const handleIncrement = (i) => {
    let info = [...data];
    info[i].quantity = Number(info[i].quantity) + 1;
    setData(info);
    cartData(i);
  };

  return (
    <>
      {data?.map((d, i) => {
        return (
          <Col className="card mb-3 shadow-none" key={d?.product_id}>
            <div className="card-body d-none d-sm-block">
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-row align-items-center">
                  <div>
                    <Link
                      to={`/product/${d.product_id}`}
                      className="text-decoration-none"
                    >
                      <img
                        src={d?.img1}
                        className="img-fluid"
                        alt="Shopping item"
                        style={{
                          width: "65px",
                          height: "50px",
                          objectFit: "cover",
                        }}
                      />
                    </Link>
                  </div>
                  <div className="ms-3">
                    <Link
                      to={`/product/${d.product_id}`}
                      className="text-decoration-none text-dark"
                    >
                      <h5>
                        {d?.product_name}
                        <small
                          className="ms-1 text-secondary"
                          style={{ fontSize: "13px" }}
                        >
                          {d?.weight}Gms.
                        </small>
                      </h5>
                      <p className="small mb-0 text-secondary">{d?.category}</p>
                    </Link>
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div
                    style={{ width: "100px" }}
                    className="d-flex align-items-center"
                  >
                    <div>
                      <button
                        onClick={() => handleDecrement(i)}
                        className="btn btn-success btn-sm me-2 p-1"
                      >
                        -
                      </button>
                    </div>
                    <div>{d?.quantity ? d.quantity : 0}</div>
                    <div>
                      <span
                        onClick={() => {
                          handleIncrement(i);
                        }}
                        className="btn btn-success btn-sm ms-2 p-1"
                      >
                        +
                      </span>
                    </div>
                  </div>
                  <div style={{ width: "120px" }}>
                    <h6 className="mb-0">
                      <TbCurrencyRupee style={{ marginTop: "-3px" }} />
                      {d?.offer_price * d?.quantity}
                    </h6>
                  </div>
                  <button
                    onClick={() => deleteValue(d?.product_id)}
                    style={{
                      color: "#c0392b",
                      backgroundColor: "transparent",
                      border: "none",
                      fontSize: "1.3em",
                      marginTop: "-5px",
                    }}
                  >
                    <FaTrashAlt />
                  </button>
                </div>
              </div>
            </div>
            {/* mobile cart div */}
            <div className="card-body d-block d-sm-none">
              <div className="d-flex flex-row align-items-center">
                <div>
                  <Link
                    to={`/product/${d.product_id}`}
                    className="text-decoration-none"
                  >
                    <img
                      src={d?.img1}
                      className="img-fluid mb-3"
                      alt="Shopping item"
                      style={{
                        width: "65px",
                        height: "50px",
                        objectFit: "cover",
                      }}
                    />
                  </Link>
                </div>
                <div className="ms-3">
                  <Link
                    to={`/product/${d.product_id}`}
                    className="text-decoration-none text-dark"
                  >
                    <h5>
                      {d?.product_name}{" "}
                      <small
                        className="ms-1 text-secondary"
                        style={{ fontSize: "13px" }}
                      >
                        {d?.weight}Gms.
                      </small>
                    </h5>
                    <p className="small mb-3 text-secondary">{d?.category}</p>
                  </Link>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div
                    style={{ width: "100px" }}
                    className="d-flex align-items-center"
                  >
                    <div>
                      <button
                        onClick={() => handleDecrement(i)}
                        className="btn btn-success btn-sm me-2 p-1"
                      >
                        -
                      </button>
                    </div>
                    <div>{d?.quantity ? d.quantity : 0}</div>
                    <div>
                      <span
                        onClick={() => {
                          handleIncrement(i);
                        }}
                        className="btn btn-success btn-sm ms-2 p-1"
                      >
                        +
                      </span>
                    </div>
                  </div>
                  <div style={{ width: "100px" }}>
                    <h6 className="mb-0">
                      <TbCurrencyRupee style={{ marginTop: "-3px" }} />
                      {d?.offer_price * d?.quantity}
                    </h6>
                  </div>
                  <button
                    onClick={() => deleteValue(d?.product_id)}
                    style={{
                      color: "#c0392b",
                      backgroundColor: "transparent",
                      border: "none",
                      fontSize: "1.3em",
                      marginTop: "-5px",
                    }}
                  >
                    <FaTrashAlt />
                  </button>
                </div>
              </div>
            </div>
          </Col>
        );
      })}
    </>
  );
}
