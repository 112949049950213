export const getLocalStorage = (keyName) => {
  return window.localStorage.getItem(keyName);
};

export const setlocalStorage = (keyName, value) => {
  window.localStorage.setItem(keyName, value);
};

export const removeLocalStorage = (keyName) => {
  window.localStorage.removeItem(keyName);
};
