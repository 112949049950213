import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { BiCaretLeft } from "react-icons/bi";
import { TbCurrencyRupee } from "react-icons/tb";
import { ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import CartCard from "./CartCard";
import { errorToaster, successToaster } from "../../utils/index";
import { SERVER_PATH } from "../../config/env";
import { userInfo } from "../../utils/appInfo";

export default function Cart() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [cartValue, setCartValue] = useState(0);
  const [stateData, setStateData] = useState({});
  const [userData, setUserData] = useState({});
  const [taxData, setTaxData] = useState({});
  const [courierPrice, setCourierPrice] = useState({});
  const [total, setTotal] = useState(0);

  // Fetch user cart data
  const fetchData = async () => {
    try {
      const { data: cData } = await axios.post(`${SERVER_PATH}get/cart.php`, {
        user_id: userInfo().email,
      });
      setData(cData || []);
    } catch (error) {
      setData([]);
    }
  };

  const fetchStateData = async () => {
    try {
      const res = await axios.post(`${SERVER_PATH}get/user.php`, {
        user_id: userInfo().email,
      });
      setStateData(res.data[0] || {});
    } catch (error) {
      console.log("Error fetching state data:", error);
    }
  };

  const getTaxData = async () => {
    try {
      const res = await axios.post(`${SERVER_PATH}get/additional_cost.php`);
      setTaxData(res.data[0] || {});
    } catch (error) {
      console.log("Error fetching tax data:", error);
    }
  };

  const courierPriceData = async (val = {}) => {
    try {
      const res = await axios.post(`${SERVER_PATH}get/delivery_cost.php`, {
        state: val.state,
      });
      setCourierPrice(res.data[0] || {});
    } catch (error) {
      console.log("Error fetching courier price data:", error);
    }
  };

  const calculateBasePrice = (grams, rate) => {
    const weight = Math.ceil(grams / 1000);
    return weight * rate;
  };

  const getDeliveryCost = useMemo(() => {
    if (!courierPrice.perkg || !data.length) return 0;
    const totalWeight = data.reduce(
      (weight, item) => weight + item.weight * item.quantity,
      0
    );
    const baseCost = calculateBasePrice(totalWeight, courierPrice.perkg);
    const fuelCost = (baseCost * (taxData.fuel || 0)) / 100;
    const gstCost = (baseCost * (taxData.gst || 0)) / 100;
    const totalCost = baseCost + fuelCost + gstCost;
    setTotal(Math.ceil(totalCost));
    return Math.ceil(totalCost);
  }, [data, taxData, courierPrice]);

  useEffect(() => {
    fetchData();
    fetchStateData();
    setUserData(userInfo());
    getTaxData();
  }, []);

  useEffect(() => {
    if (stateData?.state) {
      courierPriceData(stateData);
    }
  }, [stateData]);

  useEffect(() => {
    const total = data.reduce(
      (sum, item) => sum + item.quantity * item.offer_price,
      0
    );
    setCartValue(total);
  }, [data]);

  const removeProduct = async (id) => {
    try {
      await axios.post(`${SERVER_PATH}delete/cart.php`, {
        user_id: userData.email,
        product_id: id,
        cart_item_id: id,
      });
      fetchData();
      errorToaster({ msg: "Item removed from your cart" });
    } catch (error) {
      console.log("Error removing product:", error);
    }
  };

  const Payment = async () => {
    if(cartValue + getDeliveryCost>=500){
      try {
        const payload = {
          user_id: userInfo().email,
          order_cost: cartValue + getDeliveryCost,
        };
        const response = await axios.post(`${SERVER_PATH}post/payment.php`, payload);
        window.location.href = response.data.pay_url;
      } catch (error) {
        console.error("Error processing payment:", error);
      }
    }
    else{
      alert("Minimum Purchase limit Rs.500");
    }
    
  };

  return (
    <>
      <section className="h-100 h-custom productPage">
        <div className="container-fluid py-4 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col">
              <div className="card">
                <div className="card-body p-4">
                  <div className="row">
                    <div className="col-lg-7">
                      <div className="mb-3">
                        <Link to="/" className="text-body text-decoration-none">
                          <BiCaretLeft className="me-0" style={{ fontSize: "1.5em" }} />
                          <span>Continue shopping</span>
                        </Link>
                      </div>
                      <hr />
                      <CartCard
                        data={data}
                        deleteValue={removeProduct}
                        setData={setData}
                      />
                    </div>
                    <div className="col-lg-5">
                      <div className="card text-white rounded-3 cartPriceCard">
                        <div className="card-body">
                          <h4 className="text-uppercase">Price Details</h4>
                          <hr className="my-3" />
                          <div className="d-flex justify-content-between">
                            <p className="mb-2 text-uppercase">Sub Total</p>
                            <p className="mb-2">
                              <TbCurrencyRupee style={{ marginTop: "-3px" }} />
                              {cartValue}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="mb-2 text-uppercase">Delivery Charges</p>
                            <p className="mb-2">
                              <TbCurrencyRupee style={{ marginTop: "-3px" }} />
                              {stateData?.state !== "0" ? getDeliveryCost : 0}
                            </p>
                          </div>
                          <hr className="my-3" />
                          <div className="d-flex justify-content-between mb-2">
                            <p className="mb-2 text-uppercase">Total Price</p>
                            <p className="mb-2">
                              <TbCurrencyRupee style={{ marginTop: "-3px" }} />
                              {stateData?.state !== "0"
                                ? cartValue + getDeliveryCost
                                : cartValue}
                            </p>
                          </div>
                          <button
                            disabled={!cartValue}
                            className="btn btn-light btn-block btn-sm"
                            onClick={() =>
                              stateData.state === "0"
                                ? alert("Please update your profile address.")
                                : Payment()
                            }
                          >
                            Checkout
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
}
