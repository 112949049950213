import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "../../Breadcrumb";
// Product images
import ZardosiSingleProduct from "./ZardosiCard";

export default function Zardosi() {
  return (
    <>
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <Breadcrumbs currentPathName="Aari Pattern Paper" />
              <div className="col-md-6">
                <ul className="list-inline shop-top-menu pb-3 pt-1">
                  <li className="list-inline-item">
                    <Link
                      className="h3 text-dark text-decoration-none mr-3 text-uppercase"
                      to="/zardosi">
                      Aari Pattern Paper
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <ZardosiSingleProduct />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
