import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { BsCoin } from "react-icons/bs";
import { ImTarget } from "react-icons/im";
import { FaChartLine } from "react-icons/fa";
import aboutHero from "../../assets/ari-works-img/rb_940.png";
import inspiration from "../../assets/ari-works-img/Inspiration.jpeg";
import infrastructure from "../../assets/ari-works-img/Infrastructure.jpeg";
import incrediants from "../../assets/ari-works-img/delivery.jpeg";
import cultivation from "../../assets/ari-works-img/Shipping.jpeg";
import AboutCard from "./AboutCard";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fullViewInspiration = () => {
    Swal.fire({
      title: "Young Entrepreneur",
      text: "I was honored with the Young Entrepreneur Award by Aramseiya Virumbu Arakkatali in 2022, recognizing my dedication to innovation and excellence in tailoring and aari design.",
    });
  };

  const fullViewInfrastructure = () => {
    Swal.fire({
      title: "Homepreneur Award",
      text: "I was honored with the Homepreneur Award in 2022 by Suyasakthi Viruthugal, organized by Sakthi Masala, in recognition of my efforts in training over 1,000 students through both online and offline sessions. This award celebrates my commitment to empowering others by sharing my skills and expertise in tailoring and aari design, helping them build their own paths in the Tailoring.",
    });
  };

  const fullViewIngrediants = () => {
    Swal.fire({
      title: "Kunkumam magalir magazine",
      // text: "I was featured in Kunkumam Magalir magazine, where my expertise in aari work and my innovative business strategies in tailoring and embroidery were highlighted. This recognition reflects my dedication to the craft and my commitment to developing a successful business in the field of tailoring and aari design.",
    });
  };

  const fullViewCultivation = () => {
    Swal.fire({
      title: "SHIPPING & RETURN",
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.Laudantium dolorem vero eius magnam quas dolores beatae, illum temporibus distinctio, quia quod eos provident accusamus repellat harum ea tempore corrupti alias.",
    });
  };

  return (
    <>
      <section class="py-1 about-hero">
        <div class="container">
          <div class="row align-items-center py-4">
            <div class="col-md-8 text-white">
              <h1>About Us</h1>
              <p>
              I am G.Janani a skilled women's tailor with a passion for design that started in my childhood. With over 10 years of professional experience, I have honed my expertise in creating beautifully tailored garments, ensuring each piece is crafted with precision and care. In addition to tailoring, I am an experienced aari designer, having spent several years mastering the intricate art of aari embroidery. This blend of tailoring and aari design allows me to bring unique, elegant styles to life, adding intricate detailing and personal touches to every outfit I create.
              </p>
            </div>
            <div class="col-md-4">
              <img src={aboutHero} alt="About Hero" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      <section class="container py-3">
        <div class="row text-center pt-5 pb-3">
          <div class="col-lg-12 m-auto">
            <h1 class="h1">Our Story</h1>
            <p>
            I have been recognized for my dedication and skill in tailoring and design with prestigious awards like the Suyasakthi Homepreneur Award and the Young Women Entrepreneur Award. My work has also been featured in Kunkumam Thozhi magazine, showcasing my commitment to empowering women through fashion and craftsmanship.
            </p>
          </div>
        </div>
      </section>
      <section class="container py-1">
        <Row>
          <Col lg={3}>
            <AboutCard
              image={inspiration}
              cardStyle="aboutCard"
              title="Young Entrepreneur"
              contents="I was honored with the Young Entrepreneur Award by Aramseiya Virumbu Arakkatali in 2022, recognizing my dedication to innovation and excellence in tailoring and aari design."
              readMore={() => fullViewInspiration()}
            />
          </Col>
          <Col lg={3}>
            <AboutCard
              image={infrastructure}
              cardStyle="aboutCard"
              title="Homepreneur Award"
              contents="I was honored with the Homepreneur Award in 2022 by Suyasakthi Viruthugal, organized by Sakthi Masala, in recognition of my efforts in training over 1,000 students through both online and offline sessions. This award celebrates my commitment to empowering others by sharing my skills and expertise in tailoring and aari design, helping them build their own paths in the Tailoring."
              readMore={() => fullViewInfrastructure()}
            />
          </Col>
          <Col lg={3}>
            <AboutCard
              image={incrediants}
              cardStyle="aboutCard"
              title="Kunkumam Magalir Magazine"
              contents="I was featured in Kunkumam Magalir magazine, where my expertise in aari work and my innovative business strategies in tailoring and embroidery were highlighted. This recognition reflects my dedication to the craft and my commitment to developing a successful business in the field of tailoring and aari design."
              readMore={() => fullViewIngrediants()}
            />
          </Col>
          <Col lg={3}>
            <AboutCard
              image={cultivation}
              cardStyle="aboutCard"
              title=" "
              // contents="Lorem ipsum dolor sit amet consectetur adipisicing elit.
              //   Laudantium dolorem vero eius magnam quas dolores beatae,"
              // readMore={() => fullViewCultivation()}
            />
          </Col>
        </Row>
        <div class="row d-flex justify-content-center my-3">
          <div class="col-md-4 col-lg-4">
            <div class="px-5 services-icon-wap">
              <div class="h1 aboutIcon text-center">
                <ImTarget />
              </div>
              <h2 class="h5 mt-4 text-center">Mission</h2>
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "15px",
                  fontWeight: "400",
                }}>
                To craft high-quality, beautifully tailored garments and intricate aari designs that empower individuals and bring their unique styles to life. Through personalized service and expert craftsmanship, we aim to inspire confidence and creativity in every customer and student we serve.
              </p>
            </div>
          </div>

          <div class="col-md-4 col-lg-4">
            <div class="px-5 services-icon-wap">
              <div class="h1 aboutIcon text-center">
                <FaChartLine />
              </div>
              <h2 class="h5 mt-4 text-center">Vision</h2>
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "15px",
                  fontWeight: "400",
                }}>
                To become a leading brand in the tailoring and aari design industry, known for excellence in design, quality, and training. We envision a community where women are empowered through skills and business opportunities in tailoring and embroidery, fostering growth, independence, and artistry.
              </p>
            </div>
          </div>

          <div class="col-md-4 col-lg-4">
            <div class="px-5 services-icon-wap">
              <div class="h1 aboutIcon text-center">
                <BsCoin />
              </div>
              <h2 class="h5 mt-4 text-center">Values</h2>
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "15px",
                  fontWeight: "400",
                }}>We prioritize skill, detail, and precision in every piece we create.We believe in sharing knowledge to empower others, offering accessible training to students both online and offline. We honor traditional techniques in aari work while also embracing new trends and methods to keep our designs fresh and unique. We are dedicated to understanding and exceeding our customers' expectations, making sure each design reflects their personal style. We uphold honesty, transparency, and reliability in every interaction, creating lasting relationships with clients and students alike.

              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
