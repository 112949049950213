import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import axios from "axios";
import { SERVER_PATH } from "../../../config/env";
import Come from "../../../assets/img/comming.jpg";

const priceStyle = {
  textDecoration: "line-through",
  fontSize: "14px",
};

const offerPriceStyle = {
  color: "green",
  fontWeight: 500,
};

export default function ZardosiSingleProduct() {
  const [data, setData] = useState([]);

  const fetchData = () => {
    axios.post(`${SERVER_PATH}get/all_products.php`, {}).then((res) => {
      setData(res.data);
    });
  };

  const cardDetail = CardDetail();

  function CardDetail() {
    const arr = [];
    for (let i = 0; i < data.length; i++) {
      if (data?.[i]?.category === "zardosi")
        arr.push({
          img: data?.[i]?.img1,
          title: data?.[i]?.product_name,
          price: data?.[i]?.price,
          offerPrice: data?.[i]?.offer_price,
          offer: data?.[i]?.offer,
          productId: data?.[i]?.product_id,
        });
    }
    return arr;
  }

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {cardDetail.map((data, index) => {
        return (
          <div className="col-md-3" key={"product" + index}>
            <div className="card mb-4 product-wap rounded-3">
              <div className="card rounded-3">
                <img
                  className="card-img rounded-3 img-fluid"
                  style={{ height: "180px", objectFit: "cover" }}
                  src={data.img ? data.img : Come}
                  alt="ktownaaricreatives product"
                />
                <div className="card-img-overlay rounded-0 product-overlay d-flex align-items-center justify-content-center">
                  <ul className="list-unstyled">
                    <li>
                      <Link
                        className="btn btn-success text-white mt-2"
                        to={`/product/${data.productId}`}
                      >
                        <AiOutlineEye
                          style={{ fontSize: "1.3em", marginTop: "-3px" }}
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="card-body">
                <Link to={`/product/${data.productId}`} className="h3 text-decoration-none">
                  {data.title}
                </Link>
                <span className="d-block text-secondary my-2">
                  RS.{data.offerPrice}&nbsp;
                  <span style={priceStyle}>{data.price}</span>&nbsp;
                  <small style={offerPriceStyle}>{data.offer}%</small>
                </span>
                <Link to={`/product/${data.productId}`}>
                  <button className="btn btn-sm w-100 btn-outline-success text-uppercase">
                    Buy Now
                  </button>
                </Link>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
